/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "caption_colors_primary": "text-gray-900",
  "caption_size_default": "text-headline-xs",
  "caption_size_alternative": "text-headline-xs",
  "caption_variant_default": "transition-colors",
  "caption_variant_alternative": "transition-colors",
  "group_colors_primary": "",
  "group_size_default": "w-full",
  "group_variant_default": "relative flex flex-col items-start gap-y-1",
  "icon_colors_primary": "text-black group-data-[status=error]:text-error-500",
  "icon_size_default": "w-5 h-5",
  "icon_size_alternative": "w-5 h-5 group-focus-within:w-3.5 group-focus-within:h-3.5 group-data-[filled]:w-3.5 group-data-[filled]:h-3.5",
  "icon_variant_default": "transition-all absolute right-2 top-1/2 -translate-y-1/2 peer/icon",
  "icon_variant_alternative": "transition-all absolute right-2 top-1/2 -translate-y-1/2 peer/icon group-focus-within:top-2 group-focus-within:translate-y-0 group-data-[filled]:top-2 group-data-[filled]:translate-y-0",
  "input_colors_primary": "bg-white outline-0 ring-1 ring-inset ring-gray-300 shadow-[0_0_0_0] shadow-success-200 hover:not-focus:ring-success-500 hover:not-focus:shadow-[0_0_0_0] hover:not-focus:shadow-success-200 focus:ring-success-500 focus:shadow-[0_0_10px_0] focus:shadow-success-200 placeholder:text-gray-700 group-data-[status=error]:bg-white group-data-[status=error]:outline-0 group-data-[status=error]:ring-1 group-data-[status=error]:ring-error-500 group-data-[status=error]:hover:ring-error-500 group-data-[status=error]:ring-inset group-data-[status=error]:shadow-[0_0_0_0] group-data-[status=error]:placeholder:text-gray-700",
  "input_size_default": "p-2 w-full h-10 peer-[.absolute]/icon:pr-7",
  "input_size_alternative": "p-2 pt-6 w-full h-14",
  "input_variant_default": "transition-all rounded",
  "input_variant_alternative": "transition-all rounded placeholder:opacity-0",
  "label_colors_primary": "text-gray-900",
  "label_size_default": "text-headline-xs",
  "label_size_alternative": "text-headline-xs group-focus-within:text-headline-2xs group-data-[filled]:text-headline-2xs",
  "label_variant_default": "transition-color relative",
  "label_variant_alternative": "transition-all absolute z-1 left-2 top-1/2 -translate-y-1/2 group-focus-within:top-1 group-focus-within:translate-y-1.5 group-data-[filled]:top-1 group-data-[filled]:translate-y-1.5",
  "size_default": "w-full",
  "size_alternative": "w-full",
  "colors_primary": "",
  "status_error": "",
  "variant_default": "flex flex-col items-start gap-y-1 group",
  "variant_alternative": "flex flex-col items-start gap-y-1 group"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "TextField");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;