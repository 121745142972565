/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "segment_animation_default": "transition-all duration-300 ease-in-out first-of-type:-translate-y-2.5 third-of-type:hidden last-of-type:translate-y-2.5 peer-checked:translate-y-0 peer-checked:first-of-type:rotate-45 peer-checked:last-of-type:-rotate-45 peer-checked:second-of-type:scale-x-0",
  "segment_animation_alternative": "transition-all duration-300 ease-in-out first-of-type:-translate-y-2 last-of-type:translate-y-2 peer-checked:first-of-type:-translate-y-5 peer-checked:first-of-type:scale-x-0 peer-checked:second-of-type:rotate-45 peer-checked:third-of-type:-rotate-45 peer-checked:last-of-type:translate-y-5 peer-checked:last-of-type:scale-x-0",
  "segment_colors_default": "bg-black",
  "segment_size_default": "w-full border-0 h-1 first-of-type:w-1/2 peer-checked:first-of-type:w-full",
  "segment_variant_default": "absolute transform top-1/2 -mt-0.25 rounded-full",
  "toggle_variant_default": "peer absolute top-0 left-0 w-full h-full appearance-none cursor-pointer",
  "animation_default": "",
  "animation_alternative": "",
  "colors_default": "bg-transparent",
  "size_default": "w-7.5 h-9",
  "variant_default": "relative block cursor-pointer group/hamburger"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Hamburger");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;